@import '~app/mixins';

.container {
  background-color: #dadfe7;
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  transition: width $transition-delay;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100000;  /* Nav bar should be visible above all popups. */
  overflow: hidden;

  &:hover {
    width: 150px;

    .toggle {
      visibility: visible;
    }

    .disabled {
      pointer-events: none;

      svg {
        display: none;
      }
    }

    .logo {
      display: unset;
    }

    .logoSmall {
      display: none;
    }

    .avatar {
      display: block;
      width: 82px;
      height: 82px;
      padding: 5px;
      margin: 0 auto 13px;
      opacity: 1;

      &:after {
        border: 2px solid $color-grey;
      }
    }
  }
}

.logo {
  height: auto;
  width: auto;
  max-height: 80px;
  max-width: 80%;
  margin: 8px auto;
  display: none;
  transition: all $transition-delay;
}

.logoSmall {
  height: auto;
  width: auto;
  max-height: 80px;
  max-width: 90%;
  margin: 10px 2px 36px;
  transition: all $transition-delay;
}

.info {
  // margin-bottom: 15px;
  text-align: center;
  height: 195px;
}

.nav {
  flex: 1;
  background-color: $color-grey-dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 195px);

  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #aaa;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  @media screen and (max-height: 730px) {
    overflow: hidden;
    overflow-y: auto;
    }

    @media screen and (max-width: 1200px) {
      overflow: hidden;
      overflow-y: auto; 
    }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    // &:nth-child(2) {
    //   margin-bottom: 78px;
    // }
  }

  li {
    white-space: nowrap;

    a {
      padding: 14px;
      vertical-align: middle;
      color: $color-grey-1 !important;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;
      display: block;
      text-decoration: none;
      height: 48px;
      position: relative;

      &[disabled] {
        pointer-events: none;
      }

      span {
        vertical-align: middle;
      }
    }

    .activeItem {
      @include primary-background-color();

      color: $color-white;
      font-size: 14px;
      font-weight: 500;

      svg {
        fill: white !important;
      }
    }

    div {
      display: block;
    }

    svg {
      fill: $color-grey;
      vertical-align: middle;
      margin-right: 17px;
    }

    svg.iconProperties {
      margin-left: -5px;
      margin-right: 13px;
    }

    svg.iconMoney {
      width: 20px;
      height: 20px;
    }

    svg.iconChat {
      width: 24px;
      height: 24px;
    }

    &:hover {
      a {
        text-decoration: none;
      }

      a:not([disabled]) {
        @include primary-background-color();

        span {
          color: $color-white;
          font-size: 14px;
          font-weight: 500;
        }

        svg {
          fill: white !important;
        }
      }
    }

    a[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }
  }
}

.avatar {
  position: relative;
  border-radius: 50%;
  padding: 3px;
  margin: 22px auto;
  display: block;
  width: 46px;
  height: 46px;
  opacity: 0;
  transition: all $transition-delay;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: 2px solid $color-grey;
    border-radius: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.toggle {
  font-size: 12px;
  line-height: 18px;
  color: $color-grey-1;
  display: block;
  cursor: pointer;
  visibility: hidden;
  white-space: nowrap;
}

.iconSearch {
  width: 20px;
  height: 21px;
}

.iconProperties {
  width: 30px;
  height: 18px;
}

.iconCampaigns {
  width: 20px;
  height: 19px;
}

.iconContacts {
  width: 20px;
  height: 19px;
}

.iconCalculators {
  width: 21px;
  height: 16px;
}

.iconQuestion {
  width: 19px;
  height: 19px;
}

.iconCaret {
  width: 15px;
  height: 9px;
  margin-left: 3px;
}